import { useState, useEffect } from 'react';
import { REACT_APP_TEXT_HUMANIZER_STREAM_ENDPOINT } from '~constants';
import { useAuth } from './useAuth';

type UseHumanizedTextOptions = {
    text: string;
    tone: string;
    onError?: (error: string) => void;
    onLoading?: (loading: boolean) => void;
    onEnd?: () => void;
};

export type UseHumanizedTextReturnType = ReturnType<typeof useHumanizedText>;

export function useHumanizedText() {
    const [humanizedText, setHumanizedText] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [abortController, setAbortController] =
        useState<AbortController | null>(null);
    const { token } = useAuth();

    const startHumanizedTextStream = async ({
        text,
        tone,
        onError,
        onLoading,
        onEnd,
    }: UseHumanizedTextOptions) => {
        setHumanizedText('');
        setError(null);
        setIsLoading(true);
        onLoading?.(true);

        // Create new AbortController for this stream
        const controller = new AbortController();
        setAbortController(controller);

        try {
            const response = await fetch(
                REACT_APP_TEXT_HUMANIZER_STREAM_ENDPOINT,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        Accept: 'text/event-stream',
                    },
                    body: JSON.stringify({ text, tone }),
                    signal: controller.signal,
                },
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            if (!response.body) {
                throw new Error('Response body is null');
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let buffer = '';

            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    break;
                }

                buffer += decoder.decode(value, { stream: true });
                let newlineIndex;

                while ((newlineIndex = buffer.indexOf('\n')) !== -1) {
                    const line = buffer.slice(0, newlineIndex).trim();
                    buffer = buffer.slice(newlineIndex + 1);

                    if (line.startsWith('data: ')) {
                        const rawData = line.slice(6); // Remove "data: " prefix
                        if (rawData === '[DONE]') {
                            break;
                        }

                        try {
                            const parsed = JSON.parse(rawData);
                            if (parsed.error) {
                                setError(parsed.error);
                                onError?.(parsed.error);
                            } else if (parsed.content) {
                                setHumanizedText(
                                    (prev) => prev + parsed.content,
                                );
                            }
                        } catch (err) {
                            console.error('Error parsing data:', err);
                            setError('Error parsing data');
                            onError?.('Error parsing data');
                        }
                    }
                }
            }

            setIsLoading(false);
            onLoading?.(false);
            onEnd?.();
        } catch (err: any) {
            if (err.name === 'AbortError') {
                return;
            }

            console.error('Fetch error:', err.message || err);
            setError(err.message || 'Unexpected error occurred');
            onError?.(err.message || 'Unexpected error occurred');
            setIsLoading(false);
            onLoading?.(false);
        } finally {
            setAbortController(null);
        }
    };

    const stopHumanizedTextStream = () => {
        if (abortController) {
            abortController.abort();
            setIsLoading(false);
        }
    };

    useEffect(() => {
        return () => {
            if (abortController) {
                abortController.abort();
            }
        };
    }, [abortController]);

    return {
        humanizedText,
        isLoading,
        error,
        startHumanizedTextStream,
        stopHumanizedTextStream,
        setHumanizedText,
    };
}
