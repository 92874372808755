// This utility file emits events to Google / TikTok

import Cookies from 'js-cookie';
import { logger } from './logger';
import { v4 as uuidv4 } from 'uuid';
import { CookieKeys } from '~constants';
import {
    PostPaymentPayload,
    postPaymentApi,
} from '~components/CheckoutForm/CheckoutForm.functions';

export const emitInitiateCheckoutEvent = () => {
    logger.debug('Emitting Initiate Checkout Event');
    try {
        // TikTok
        (window as any).ttq.track('InitiateCheckout', {
            content_type: 'product',
            content_id: '1',
            content_name: 'Studybuddy Intro Plan',
            currency: 'USD',
            quantity: 1,
            value: 0.99,
        });
        logger.debug('Emitted TikTok Begin Checkout Event');
    } catch (err) {
        logger.debug('Failed to emit TIkTok Initiate Checkout Event');
        logger.error(err as Error);
    }
    try {
        // Google
        (window as any).gtag('event', 'begin_checkout', {
            currency: 'USD',
            transaction_id: uuidv4(),
            value: 0.99,
            items: [
                {
                    item_id: '1',
                    item_name: 'Studybuddy Intro Plan',
                },
            ],
        });
        logger.debug('Emitted Google Begin Checkout Event');
    } catch (err) {
        logger.debug('Failed to emit Google Initiate Checkout Event');
        logger.error(err as Error);
    }
};

export const emitPlaceOrderEvent = () => {
    logger.debug('Emitting Place Order Event');
    try {
        // TikTok
        (window as any).ttq.track('PlaceAnOrder', {
            content_type: 'product',
            content_id: '1',
            content_name: 'Studybuddy Intro Plan',
            currency: 'USD',
            quantity: 1,
            value: 0.99,
        });
        logger.debug('Emitted TikTok Place Order Event');
    } catch (err) {
        logger.debug('Failed to emit TikTok Place Order Event');
        logger.error(err as Error);
    }
    try {
        // Google
        (window as any).gtag('event', 'purchase', {
            currency: 'USD',
            transaction_id: uuidv4(),
            value: 0.99,
            items: [
                {
                    item_id: '1',
                    item_name: 'Studybuddy Intro Plan',
                },
            ],
        });

        (window as any).gtag('event', 'conversion', {
            send_to: 'AW-11343450609/NOqUCP3-hvUYEPGj_aAq',
            value: 0.99,
            currency: 'USD',
            transaction_id: uuidv4(),
        });
        logger.debug('Emitted Google Place Order Event');
    } catch (err) {
        logger.debug('Failed to emit Google Place Order Event');
        logger.error(err as Error);
    }
    // try {
    //     (window as any).fbq('track', 'Purchase', {
    //         currency: 'USD',
    //         value: 0.99,
    //     });
    // } catch (err) {
    //     logger.debug('Failed to emit Facebook Meta Place Order Event');
    //     logger.error(err as Error);
    // }

    try {
        const tuneTransactionId = Cookies.get(CookieKeys.tuneTransactionId);
        console.log('TUNE: track conversion started.', tuneTransactionId);
        const url = `https://glitchy.go2cloud.org/aff_lsr?offer_id=936&transaction_id=${tuneTransactionId}`;
        fetch(url, { mode: 'no-cors' })
            .then((response) => response.text())
            .then((data) => {
                window.localStorage.setItem('conversionState', '1');
                console.log('TUNE: track conversion done.');
            })
            .catch((error) => console.log('ERROR:', error));
        /* (window as any).tdl.convert({
            amount: `0.99`,
            adv_sub: 'USD',
            adv_unique1: 'SB_INTRO_PLAN',
        }); */
    } catch (err) {
        logger.debug('TUNE : Failed to emit tune Purchase Event');
        logger.error(err as Error);
    }

    try {
        (window as any).snaptr('track', 'PURCHASE', {
            price: 0.99,
            currency: 'USD',
        });
    } catch (err) {
        logger.debug('Failed to emit Snaptr Purchase Event');
        logger.error(err as Error);
    }
};

export const emitReferralEvent = (
    token: string | undefined,
    customerStripeId: string,
) => {
    if (!token || !customerStripeId) {
        logger.debug(
            'No token or customerStripeId found',
            token,
            customerStripeId,
        );
        return;
    }
    // Referrer is the user who referred this user
    const referrer = Cookies.get(CookieKeys.referralCookie);
    const fbclid = Cookies.get(CookieKeys.fbClickId);
    const fbp = Cookies.get(CookieKeys.fbp);
    const payload: PostPaymentPayload = {
        status: 'purchaseComplete',
    };
    if (referrer) {
        payload.referralId = referrer;
    }
    if (fbclid) {
        payload.fbclid = fbclid;
    }
    if (fbp) {
        payload.fbp = fbp;
    }
    try {
        postPaymentApi(token, payload);
    } catch (err) {
        logger.debug('Failed to emit Referral Event');
        logger.error(err as Error);
    }
};

export const emitViralShareEvent = () => {
    try {
        // Google
        (window as any).gtag('event', 'viral_share', {
            currency: 'USD',
            transaction_id: uuidv4(),
        });
        (window as any).gtag('event', 'conversion', {
            send_to: 'AW-11343450609/NOqUCP3-hvUYEPGj_aAq',
            transaction_id: uuidv4(),
        });
        logger.debug('Emitted Google Share Event');
    } catch (err) {
        logger.debug('Failed to emit Google Share Event');
        logger.error(err as Error);
    }
};

export const firePixelsAddToCart = () => {
    try {
        console.log('firePixels New Pricing >>>');
        let GAEvent = {
            currency: 'USD',
            value: 0,
            items: [
                {
                    item_id: '1',
                    item_name: 'StudyBuddy',
                },
            ],
        };
        /* (window as any).gtag("event", type, GAEvent); */
        (window as any).gtag('event', 'add_to_cart', GAEvent);

        (window as any).ttq.track('AddToCart', {
            currency: 'USD',
            content_type: 'product',
            content_name: 'StudyBuddy',
            content_id: '1',
            quantity: 1,
            value: 0,
        });
        (window as any).fbq('track', 'AddToCart', {
            currency: 'USD',
            value: 0,
        });

        try {
            (window as any).klaviyo.push([
                'track',
                'Started Checkout',
                {
                    $event_id: new Date().getTime(),
                    value: 0,
                    ItemNames: ['StudyBuddy'],
                    CheckoutURL: 'https://studybuddy.gg/register',
                    Categories: [],
                    Items: [
                        {
                            ProductID: '1',
                            SKU: 'StudyBuddy',
                            ProductName: 'StudyBuddy',
                            Quantity: 1,
                            ItemPrice: 0,
                            RowTotal: 0,
                            ProductURL: 'https://studybuddy.gg',
                            ImageURL:
                                'https://studybuddy.gg/assets/images/logo_square.png',
                        },
                    ],
                },
            ]);
        } catch (err) {
            logger.error(err as Error);
        }

        (window as any).snaptr('track', 'ADD_CART', {
            price: 0.99,
            currency: 'USD',
        });

        console.log('firePixels things worked up until here >>>');
    } catch (err) {
        logger.error(err as Error);
    }
};

export const emitSemeseterPlanEvent = async () => {
    try {
        // Google
        (window as any).gtag('event', 'sb-semester-plan', {
            currency: 'USD',
            transaction_id: uuidv4(),
            value: 39.99,
            items: [
                {
                    item_id: '3',
                    item_name: 'Studybuddy Semester Plan',
                },
            ],
        });
        logger.debug('Emitted Google Semester Plan Event');
    } catch (err) {
        logger.debug('Failed to emit Google Semester Plan Event');
        logger.error(err as Error);
    }
};

export const emitOnboardingFeedbackEvent = async (feedback: boolean) => {
    try {
        // Google
        const feedbackEvent = feedback
            ? 'onboarding-feedback-positive'
            : 'onboarding-feedback-negative';
        (window as any).gtag('event', feedbackEvent);
        logger.debug('Emitted onboarding positive feedback');
    } catch (err) {
        logger.debug('Failed to emit onboarding positive feedback');
        logger.error(err as Error);
    }
};

export const emitSemesterUpgradeFromOnboardingEvent = async () => {
    try {
        // Google
        const semesterUpgradeEvent = 'semester-purchase-onboarding';
        (window as any).gtag('event', semesterUpgradeEvent);
        logger.debug('Emitted semester upgrade feedback');
    } catch (err) {
        logger.debug('Failed to emit semester upgrade feedback');
        logger.error(err as Error);
    }
};
export const emitSemesterUpgradeFromDashboardEvent = async () => {
    try {
        // Google
        const semesterUpgradeEvent = 'semester-purchase-dashboard';
        (window as any).gtag('event', semesterUpgradeEvent);
        logger.debug('Emitted semester upgrade feedback');
    } catch (err) {
        logger.debug('Failed to emit semester upgrade feedback');
        logger.error(err as Error);
    }
};
export const emitTurboUpgradeEvent = async () => {
    try {
        // Google
        const turboUpgradeEvent = 'turbo-purchase-onboarding';
        (window as any).gtag('event', turboUpgradeEvent);
        logger.debug('Emitted turbo upgrade feedback');
    } catch (err) {
        logger.debug('Failed to emit turbo upgrade feedback');
        logger.error(err as Error);
    }
};
const META_EVENTS = [
    'CompleteRegistration',
    'InitiateCheckout',
    'Purchase',
    'Subscribe',
];

export const fireMetaPixel = async (eventName: string, data: Object) => {
    if (META_EVENTS.includes(eventName)) {
        // This emits an event to facebook
        try {
            (window as any).fbq('track', eventName, data);
        } catch (err) {
            // handle this error.
            console.log('Error firing pixels');
        }
    } else {
        // firing an irrelevant event
    }
};

export const emitCrossSellUpSellEvent = async (eventName: string) => {
    try {
        // Google
        (window as any).gtag('event', eventName);
        logger.debug('Emitted Cross sell upSell event');
    } catch (err) {
        logger.debug('Failed to emit Cross sell upSell event');
        logger.error(err as Error);
    }
};
