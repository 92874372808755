export enum AppRoutes {
    Home = '/',
    Dashboard = '/dashboard',
    Profile = '/profile',
    DashboardContact = '/contact',
    Login = '/login',
    Register = '/register',
    Signin_Redirect = '/signin-redirect',
    Signout_Redirect = '/signout-redirect',
    ForgetPassword = '/forget-password',
    DashboardWelcomeVideo = '/dashboard?how-to-use=true',
    Privacy = '/privacy-policy',
    Terms = '/terms-conditions',
    NewHomepage = '/new-home-page',
    AlternativePage = '/alternative',
    WriterTool = '/writer-tool',
    WriterSteps = '/writer-steps',
    WriterContentgenerator = '/writer-steps/content-generator',
    WriterAITextHumanizer = '/writer-steps/AI-text-humanizer',
    WriterAIDetector = '/writer-steps/AI-detector',
    WriterDocuments = '/writer-steps/Documents',
    AppRedirect = '/app-redirect',
    AccountDelete = '/account-delete',
    Mobile_Checkout_Redirect = '/mobile-checkout',
}

export enum Assets {
    googleSignInBtn = '/assets/images/sso-images/btn_google_signin.png',
    logo = '/assets/images/logo.png',
    workingDemo = '/assets/images/working-demo.png',
    demoImage = '/assets/images/demo-img.png',
    reviewsImage = '/assets/images/reviews.png',
    partnerLogoMoodle = '/assets/images/partnerLogos/moodle.png',
    partnerLogoCanvas = '/assets/images/partnerLogos/canvas.png',
    partnerLogoBlackboard = '/assets/images/partnerLogos/blackboard.png',
    partnerLogoTopHat = '/assets/images/partnerLogos/tophat.png',
    videoThumbnail = '/assets/images/video-thumbnail.png',
    pinExtensionImage = '/assets/images/pin_extension.png',
    onboardingStep1 = 'https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/Step%201.mp4',
    onboardingStep2 = 'https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/Step%202.mp4',
    onboardingStep3 = '/assets/images/onboarding-step3-img.png',
    onboardingStep4 = 'https://studybuddy-assets.s3.us-west-2.amazonaws.com/new_onboarding_step_4.mp4',
    onboardingStep5 = 'https://studybuddy-assets.s3.us-west-2.amazonaws.com/new_onboarding_step_5.mp4',
    onboadingStepInstall = 'https://studybuddy-assets.s3.us-west-2.amazonaws.com/step1.mp4',
    onboadingStepPin = 'https://studybuddy-assets.s3.us-west-2.amazonaws.com/step2.mp4',
    onboadingStepHowToUse = 'https://studybuddy-assets.s3.us-west-2.amazonaws.com/onboarding_howtouse.mp4',
    onboadingStepSecretMode = 'https://studybuddy-assets.s3.us-west-2.amazonaws.com/onboarding_secretmode.mp4',
    onboardingExtensionTutorial = 'https://studybuddy-assets.s3.us-west-2.amazonaws.com/extension-tutorial-full.mp4',
    onboardingExtensionTutorialNew = '/assets/videos/extension-setup-video.mp4',
    HIWScreenshotDesktop = '/assets/videos/screenshot-desktop.mp4',
    HIWHighlightDesktop = '/assets/videos/highlight-desktop.mp4',
    HIWScreenshotMobile = '/assets/videos/screenshot-mobile.mp4',
    HIWHighlightMobile = '/assets/videos/highlight-mobile.mp4',
    LandingPageHelpWithTestDeskTop = '/assets/videos/help_with_test_desktop.mp4',
    LandingPageHelpWithTestMobile = '/assets/videos/help_with_test_mobile.mp4',
    LandingPageHelpWithTestDesktopPoster = '/assets/videos/help_with_test_poster_desktop.png',
    LandingPageHelpWithTestMobilePoster = '/assets/videos/help_with_test_poster_mobile.png',
    viralLoopBackground = '/assets/images/boy_space.jpg',
    vectorBackground = '/assets/images/lines_vector.svg',
    usersSvg = '/assets/images/users.svg',
    whatsAppLogo = '/assets/images/whatsapp.png',
    iMessageLogo = '/assets/images/imessage.png',
    copyIcon = '/assets/images/copy.png',
    telegramLogo = '/assets/images/telegramLogo.png',
    twitterLogo = '/assets/images/twitterLogo.png',
    /* registerSidePanel = '/assets/images/signupsidepanel.svg', */
    registerSidePanel = '/assets/images/side-panel-image.svg',
    shareModalImg = '/assets/images/peoples.svg',
    oliverSign = '/assets/images/oliver-sign.png',
    institutionHarvard = '/assets/images/institutions/harvard.png',
    institutionMIT = '/assets/images/institutions/mit.png',
    institutionStanford = '/assets/images/institutions/stanford.png',
    institutionBerkeley = '/assets/images/institutions/berkeley.png',
    institutionMcGill = '/assets/images/institutions/mcgill.png',
    institutionThc = '/assets/images/institutions/thc.png',
    ChatGPTLogo = '/assets/images/ChatGPTLogo.svg',
    ChatGPTLogoAlt = '/assets/images/ChatGPTLogo_Alt.svg',
    ChatGPTLogoBackground = '/assets/images/ChatGPTLogoBackground.svg',
    Brain = '/assets/images/Brain.svg',
    Target = '/assets/images/Target.svg',
    Neuron = '/assets/images/Neuron.svg',
    LightBulb = '/assets/images/LightBulb.svg',
    Button = '/assets/images/howItworks/Button.mp4',
    Floating = '/assets/images/howItworks/Floating.mp4',
    Screenshot = '/assets/images/howItworks/Screenshot.mp4',
    SafeCheckout = '/assets/images/safe-checkout.png',
    UserReview = '/assets/images/user-reviews.png',
    StudybuddyBlackLogo = '/assets/images/studybuddy_black_logo.png',
    userReviewMobile1 = '/assets/images/carousel/carousel_one.svg',
    userReviewMobile2 = '/assets/images/carousel/carousel_two.svg',
    userReviewMobile3 = '/assets/images/carousel/carousel_three.svg',
    userReviewMobile4 = '/assets/images/carousel/carousel_four.svg',
    featuresWritersPage = '/assets/images/writer-tool/features-writer-page.svg',
    featuresWritersPageMobile = '/assets/images/writer-tool/features-writer-page-mobile.svg',
    trustPilotWriter = '/assets/images/writer-tool/trustpilot_container_img_writer.svg',
    trustPilotWriterMobile = '/assets/images/writer-tool/trustpilot_container_img_writer_mobile.png',
    ratingDesktop = '/assets/images/rating-desktop.webp',
    peopleGroup = '/assets/images/writer-tool/people-group.png',
    reviewsWriterTool = '/assets/images/writer-tool/reviews-writer-page.png',
    reviewsWriterToolMobile = '/assets/images/writer-tool/reviews-writer-page-mobile.png',
    WriterHeroVideoPoster = '/assets/videos/writer-tool-main-video-poster.png',
    WriterHeroVideo = '/assets/videos/writer-tool-main-video.mp4',
    essayWriting = '/assets/images/undetectable-essay-writting.webp',
    humanizeWriting = '/assets/images/humanize-writting.webp',
    citeFormate = '/assets/images/cite-format.webp',
    writeLikeYou = '/assets/images/write-just-like-you.webp',
    save45 = '/assets/images/you-save-45.svg',
    save45Writer = '/assets/images/you-save-45.webp',
    save45LP = '/assets/images/you-save-45-LP.webp',
    peopleGroupLp = '/assets/images/people-logo-lp.png',
    trustpilotLp = '/assets/images/trustpilot-lp.png',
    peopleGroupMobileLp = '/assets/images/people-group-lp-mobile.png',
    poweredByStripe = '/assets/images/powered-by-stripe.png',
    poweredByStripeDesktop = '/assets/images/powered-by-stripe-desktop.png',
    appLinkQrCode = '/assets/images/app-link-qrcode.png',
    upgradeSemrMobile = '/assets/images/dashboard-upgrade-sem-mobile.png',
    landingPageDesktopReviews = '/assets/images/new_reviews.webp',
    landingPageDesktopReviewsMobile = '/assets/images/new_reviews-mobi.webp',
    tryNowDesktop = '/assets/images/trial-popup-desktop.png',
    tryNowMobile = '/assets/images/trial-popup-mobile.png',
    appDescriptionDesktop = 'assets/images/dashboard-app-description-desktop.png',
    chromeExtensionDesktop = 'assets/images/dashboard-chrome-extension.png',
    writerDescriptionDesktop = 'assets/images/dashboard-writer-description-desktop.png',
    upgradeSemDesktop = 'assets/images/dashboard-upgrade-sem-desktop.png',
    semesterUpsellDesktop = 'assets/images/onboarding-semester-upsell-desktop.svg',
    semesterUpsellPrice = 'assets/images/upsell-pricing-desktop.png',
    semesterUpsellFeatures = 'assets/images/upsell-features.png',
    semesterDealPopupDesktop = 'assets/images/semesterdeal-popup-desktop.png',
    semesterDealPopupMobile = 'assets/images/semesterdeal-popup-mobile.png',
    heroGifDesktop = 'assets/videos/hero-gif-desktop.mp4',
    heroGifMobile = 'assets/videos/hero-gif-mobile.mp4',
    heroVid1 = 'assets/videos/video1.mp4',
    heroVid2 = 'assets/videos/video2.mp4',
    heroVid3 = 'assets/videos/video3.mp4',
    heroVid4 = 'assets/videos/video4.mp4',
    heroPoster1 = 'assets/videos/hero-section-poster1.webp',
    heroPoster2 = 'assets/videos/hero-section-poster2.webp',
    heroPoster3 = 'assets/videos/hero-section-poster3.webp',
    heroPoster4 = 'assets/videos/hero-section-poster4.webp',
    onboardingTurboCrossSellDesktop = 'assets/images/onboarding-turbo-crossSell-desktop.png',
    TurboModeLogo = 'assets/images/turbo-mode-logo.png',
    GiftGraduateHat = '/assets/images/graduate-hat-gift.png',
    GiftRocket = '/assets/images/gift-rocket.png',
    Review1 = '/assets/images/review-1.webp',
    Review2 = '/assets/images/review-2.webp',
    Review3 = '/assets/images/review-3.webp',
    Review4 = '/assets/images/review-4.webp',
    Review5 = '/assets/images/review-5.webp',
    Review6 = '/assets/images/review-6.webp',
    Review7 = '/assets/images/review-7.webp',
    Review8 = '/assets/images/review-8.webp',
    Review9 = '/assets/images/review-9.webp',
    WriterReview1 = '/assets/images/writer-review-1.webp',
    WriterReview2 = '/assets/images/writer-review-2.webp',
    WriterReview3 = '/assets/images/writer-review-3.webp',
    WriterReview4 = '/assets/images/writer-review-4.webp',
    WriterReview5 = '/assets/images/writer-review-5.webp',
    WriterReview6 = '/assets/images/writer-review-6.webp',
    WriterReview7 = '/assets/images/writer-review-7.webp',
    WriterReview8 = '/assets/images/writer-review-8.webp',
    WriterReview9 = '/assets/images/writer-review-9.webp',
}

export enum icons {
    google = '/assets/icons/google.svg',
    apple = '/assets/icons/apple.svg',
    rightArrowWhite = '/assets/icons/rightArrowWhite.svg',
    downArrowWhite = '/assets/icons/downArrowWhite.svg',
    leftAngleArrow = '/assets/icons/left-angle-arrow.svg',
    graduation = '/assets/icons/graduate-hat.svg',
    graduationBlack = '/assets/icons/graduate-hat-black.svg',
    home = '/assets/icons/home.svg',
    homeActive = '/assets/icons/home-active.svg',
    user = '/assets/icons/user.svg',
    userActive = '/assets/icons/user-active.svg',
    message = '/assets/icons/message.svg',
    messageActive = '/assets/icons/message-active.svg',
    affiliate = '/assets/icons/affiliate.svg',
    card = '/assets/icons/card.svg',
    dashboardCard = '/assets/icons/dashboard-card.svg',
    copy = '/assets/icons/copy.svg',
    settings = '/assets/icons/settings.svg',
    search = '/assets/icons/search.svg',
    wave = '/assets/icons/wave.svg',
    logout = '/assets/icons/logout.svg',
    playbtn = '/assets/icons/play-btn.png',
    facebook = '/assets/icons/facebook.svg',
    instagram = '/assets/icons/instagram.svg',
    tictoc = '/assets/icons/tictoc.svg',
    youtube = '/assets/icons/youtube.svg',
    youtubeWhite = '/assets/icons/youtube-white.svg',
    rightUpArrow = '/assets/icons/arrow-up-right.svg',
    edit = '/assets/icons/edit.svg',
    email = '/assets/icons/email.svg',
    menu = '/assets/icons/menu.png',
    close = '/assets/icons/close.png',
    mastercard = '/assets/icons/mastercard.svg',
    dashboardMasterCard = '/assets/icons/dashboard-master.svg',
    visa = '/assets/icons/visa.svg',
    dashboardVisa = '/assets/icons/dashboard-visa.svg',
    amex = '/assets/icons/amex.svg',
    dashboardAmex = '/assets/icons/dashboard-amex.svg',
    discover = '/assets/icons/discover.png',
    dashboardDiscover = '/assets/icons/dashboard-discover.svg',
    gpay = '/assets/icons/gpay.svg',
    applepay = '/assets/icons/applepay.png',
    linkShare = '/assets/icons/linkShare.svg',
    pattern = '/assets/icons/pattern.svg',
    share = '/assets/icons/share.svg',
    rightUpArrowPurple = '/assets/icons/arrow-up-right-purple.svg',
    externalLink = '/assets/icons/external-link.svg',
    paypal = '/assets/icons/paypal.png',
    callSupport = '/assets/icons/call-support.svg',
    callSupportGray = '/assets/icons/call-support-gray.svg',
    roundedCross = '/assets/icons/rounded-cross.svg',
    roundedCrossGray = '/assets/icons/rounded-cross-gray.svg',
    roundedStar = '/assets/icons/rounded-star.svg',
    roundedStarGray = '/assets/icons/rounded-star-gray.svg',
    disclaimer = '/assets/icons/disclaimer.svg',
    chromeBlue = '/assets/icons/chrome-blue.svg',
    chromeWhite = '/assets/images/chrome-white.svg',
    chromeGray = '/assets/icons/chrome-gray.svg',
    checkBlue = '/assets/images/check-blue.svg',
    downloadBlue = '/assets/icons/download-blue.svg',
    rightAngleArrowWhite = '/assets/icons/right-angle-arrow-white.svg',
    rightAngleArrowGrey = '/assets/icons/right-icon-grey.svg',
    shareReferral = '/assets/icons/share_referral.svg',
    applepayBordered = '/assets/images/writer-tool/applepay-borderd.svg',
    visaBordered = '/assets/images/writer-tool/visa-borderd.svg',
    mastercardBordered = '/assets/images/writer-tool/mastercard-borderd.svg',
    amexBordered = '/assets/images/writer-tool/amex-borderd.svg',
    gpayBordered = '/assets/images/writer-tool/gpay-borderd.svg',
    ques_ans_light = '/assets/images/writer-tool/10M_ques_answered_light.svg',
    robot = '/assets/images/writer-tool/robot.svg',
    magnifyingGlass = '/assets/images/writer-tool/magnifying-glass.svg',
    policeOfficer = '/assets/images/writer-tool/man-police-officer.svg',
    ninja = '/assets/images/writer-tool/ninja.svg',
    arrowRightTilt = '/assets/images/arrow-right-tilt.svg',
    arrowRightTiltBlue = '/assets/images/arrow-right-tilt-blue.svg',
    chevronDownGray = '/assets/icons/chevron-down-grey.svg',
    dashboardUnionPay = '/assets/icons/dashboard-union-pay.svg',
    dashboardJCB = '/assets/icons/dashboard-jcb.svg',
    rocket = '/assets/icons/rocket.svg',
    speechBalloon = '/assets/icons/speech-balloon.svg',
    chart = '/assets/icons/chart.svg',
    partyPopper = '/assets/icons/party-popper.svg',
    FBBlack = '/assets/icons/facebook-black-logo.svg',
    WhatsappBlack = '/assets/icons/whatsapp-black-logo.svg',
    OnboardingArrow = '/assets/icons/onboarding-arrow.svg',
    eyeIcon = '/assets/icons/password-eye-icon.svg',
    whiteCircleArrow = '/assets/icons/white-circle-arrow.svg',
    pencilIcon = '/assets/icons/pencil.svg',
    wavingHand = '/assets/icons/waving-hand.svg',
    thinkingFace = '/assets/icons/thinking-face.png',
    maticWand = '/assets/icons/magic-wand.png',
    faceWithMonocie = '/assets/icons/face-with-monocie.png',
    GraduateHatWhite = '/assets/icons/graduate-hat-white.png',
    CheckWhite = '/assets/icons/check-white.svg',
    MultiStar = '/assets/icons/multi-star.svg',
    Puzzle = '/assets/icons/puzzle.svg',
    APlusTilt = '/assets/icons/a-plus.svg',
    GradStudent = '/assets/icons/grad-student.svg',
    Note = '/assets/icons/note.svg',
    sadFaceVector = '/assets/icons/sad-face-vector.svg',
    TurboTopArrow = '/assets/icons/turbo-top-arrow.svg',
    Badge = '/assets/icons/badge.svg',
    ChartTurbo = '/assets/icons/chart-turbo.svg',
    Calculator = '/assets/icons/calculator.svg',
    Incognito = '/assets/icons/incognito.svg',
    Aplus = '/assets/icons/aplus.svg',
    Timer = '/assets/icons/timer.svg',
    WrappedGift = '/assets/icons/wrapped-gift.svg',
    HighVoltage = '/assets/icons/high-voltage.svg',
    GiftPencil = '/assets/icons/pencil-gift.svg',
    GrayRoundedArrow = '/assets/icons/carousel-arrow.svg',
    Hyphen = '/assets/icons/hyphen.svg',
    WhiteCheck = '/assets/icons/white-check.svg',
}

// NOTE: The extension relies on these cookie keys so all changes must be reflected in the extension
export enum CookieKeys {
    idTokenCookie = 'sb_id',
    accessTokenCookie = 'sb_at',
    refreshTokenCookie = 'sb_rt',
    accountStatus = 'sb_act',
    referralCookie = 'sb_rfid',
    userIdCookie = 'sb_usid',
    pricingDesignCookieKey = 'sb_pricing_design_id',
    weeklyPricingCookieKey = 'sb_updated_weekly_pricing_id',
    pricingTestCookieKey = 'sb_pricing_test_id',
    fbClickId = 'sb_fbclid',
    tuneTransactionId = 'sb_tune_transaction_id',
    fbp = '_fbp',
}

export enum CONTENT_ACTION {
    GenerateTitle = 'generateTitle',
    GenerateContent = 'generateContent',
    Rewrite = 'rewrite',
}
