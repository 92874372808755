/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "studybuddyPaypal",
            "endpoint": "https://ywtm80lrzh.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "studybuddyapi",
            "endpoint": "https://tb1oz6stp2.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "studybuddystripewebhooks",
            "endpoint": "https://pr4slb3frg.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:93da248e-c5db-44cf-bedc-4eaf816be129",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_BAWRKwOyt",
    "aws_user_pools_web_client_id": "hjhop8oee2jh4h0mf6c8gh7h9",
    "oauth": {
        "domain": "studybuddy22a29284-22a29284-prod.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/signin-redirect/,https://studybuddy.gg/signin-redirect/,https://sandbox.d1y0i8lb7zac9d.amplifyapp.com/signin-redirect/",
        "redirectSignOut": "http://localhost:3000/signout-redirect/,https://studybuddy.gg/signout-redirect/,https://sandbox.d1y0i8lb7zac9d.amplifyapp.com/signout-redirect/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "CancellationRequestsDB-prod",
            "region": "us-west-2"
        },
        {
            "tableName": "UserActivity-prod",
            "region": "us-west-2"
        },
        {
            "tableName": "db-questions-table-prod",
            "region": "us-west-2"
        },
        {
            "tableName": "submissions-prod",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
