export const STRIPE_PUBLISHABLE_KEY = process.env
    .REACT_APP_STRIPE_PUBLISHABLE_KEY as string;

export const STAGE_URL = process.env.REACT_APP_STAGE_URL as string;
export const SENTRY_ENABLE_REPORT = process.env
    .REACT_APP_SENTRY_ENABLE_REPORT as string;

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN as string;
export const SENTRY_TRACES_SAMPLE_RATE = process.env
    .REACT_APP_SENTRY_TRACES_SAMPLE_RATE
    ? parseInt(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE, 10)
    : 1.0;

export const SENTRY_LOGGING_URL = process.env
    .REACT_APP_SENTRY_LOGGING_URL as string;

export const PAYPAL_CLIENT_ID = process.env
    .REACT_APP_PAYPAL_CLIENT_ID as string;
export const PAYPAL_DEFAULT_PLAN_ID = process.env
    .REACT_APP_PAYPAL_DEFAULT_PLAN_ID as string;
export const SEMESTER_PLAN_PRICE_ID = process.env
    .REACT_APP_SEMESTER_PLAN_PRICE_ID as string;

export const STREAM_AI_CONTENT_FUNCTION_URL = process.env
    .REACT_APP_STREAM_AI_CONTENT_ENDPOINT as string;

export const REACT_APP_TEXT_HUMANIZER_STREAM_ENDPOINT = process.env
    .REACT_APP_TEXT_HUMANIZER_STREAM_ENDPOINT as string;
