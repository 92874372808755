import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';
import { CONTENT_ACTION, STREAM_AI_CONTENT_FUNCTION_URL } from '~constants';

type UseStreamOptions = {
    action: CONTENT_ACTION;
    data: Record<string, any>;
    onError?: (error: string) => void;
    onLoading?: (loading: boolean) => void;
    onEnd?: () => void;
};

type StreamResponse = {
    content: string;
    error?: string;
};

export type UseStreamAIContentReturnType = ReturnType<
    typeof useStreamAIContent
>;

export function useStreamAIContent() {
    const [streamData, setStreamData] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [abortController, setAbortController] =
        useState<AbortController | null>(null);

    const { token } = useAuth();

    const startStream = async ({
        action,
        data,
        onError,
        onLoading,
        onEnd,
    }: UseStreamOptions) => {
        setStreamData('');
        setError(null);
        setIsLoading(true);
        onLoading?.(true);

        // Create new AbortController for this stream
        const controller = new AbortController();
        setAbortController(controller);

        try {
            const response = await fetch(STREAM_AI_CONTENT_FUNCTION_URL, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    Accept: 'text/event-stream',
                },
                body: JSON.stringify({ action, ...data }),
                signal: controller.signal,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            if (!response.body) {
                throw new Error('Response body is null');
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let buffer = '';

            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    break;
                }

                buffer += decoder.decode(value, { stream: true });
                let newlineIndex;

                while ((newlineIndex = buffer.indexOf('\n')) !== -1) {
                    const line = buffer.slice(0, newlineIndex).trim();
                    buffer = buffer.slice(newlineIndex + 1);

                    if (line.startsWith('data: ')) {
                        const rawData = line.slice(6); // Remove "data: " prefix
                        if (rawData === '[DONE]') {
                            break;
                        }

                        try {
                            const parsed: StreamResponse = JSON.parse(rawData);
                            if (parsed.error) {
                                setError(parsed.error);
                                onError?.(parsed.error);
                            } else if (parsed.content) {
                                setStreamData((prev) => prev + parsed.content);
                            }
                        } catch (err) {
                            console.error('Error parsing data:', err);
                            setError('Error parsing data');
                            onError?.('Error parsing data');
                        }
                    }
                }
            }

            setIsLoading(false);
            onLoading?.(false);
            onEnd?.();
        } catch (err: any) {
            // Ignore abort errors
            if (err.name === 'AbortError') {
                return;
            }

            console.error('Fetch error:', err.message || err);
            setError(err.message || 'Unexpected error occurred');
            setIsLoading(false);
            onLoading?.(false);
            onError?.(err.message || 'Unexpected error occurred');
        } finally {
            setAbortController(null);
        }
    };

    const stopStream = () => {
        if (abortController) {
            abortController.abort();
            setIsLoading(false);
        }
    };

    useEffect(() => {
        return () => {
            if (abortController) {
                abortController.abort();
            }
        };
    }, [abortController]);

    return {
        streamData,
        isLoading,
        error,
        startStream,
        stopStream,
        setStreamData,
    };
}
